<template>
  <div>
    <div
      v-if="isSupplier"
      class="d-flex justify-content-end mb-1"
    >
      <div class="view-options d-flex justify-content-between">
        <b-form-radio-group
          v-model="itemView"
          class="ml-1 list item-view-radio-group"
          buttons
          size="sm"
          button-variant="outline-primary"
        >
          <b-form-radio
            v-for="option in itemViewOptions"
            :key="option.value"
            :value="option.value"
          >
            <feather-icon
              :icon="option.icon"
              size="18"
            />
          </b-form-radio>
        </b-form-radio-group>
      </div>
    </div>
    <TradingRequestFilter
      :legals.sync="buyerFilter"
      :countries.sync="buyerCountriesFilter"
      :statuses.sync="statusFilter"
      :verified.sync="verifiedFilter"
    />

    <!-- Table Container Card -->
    <b-card
      v-if="!isSupplier"
      no-body
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('showEntriesQty') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button
              variant="primary"
              :to="{ name: 'trading-requests-add', params: { id: 'new' } }"
            >
              {{ $t('addRequest') }}
            </b-button>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refRequestListTable"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        :sort-compare="sortCompare"
        :busy="isLoading"
        show-empty
        :empty-text="$t('noMatchingText')"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
      >
        <!-- Loader -->
        <template #table-busy>
          <table-loader />
        </template>

        <!-- Column: Id -->
        <template #cell(id)="data">
          <p class="mb-0">
            {{ data.item.id }}
          </p>
        </template>

        <!-- Column: Date -->
        <template #cell(date)="data">
          <p class="mb-0">
            {{ resolveDate(data.item.created_at) }}
          </p>
          <p class="mb-0">
            {{ resolveDate(data.item.delivery_datetime) }}
          </p>
        </template>

        <!-- Column: Buyer -->
        <template #cell(buyer)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="getRequestInfo(data.item).buyer.avatar_url"
                :text="avatarText(getRequestInfo(data.item).buyer.name)"
                variant="light-primary"
              />
            </template>
            <span class="font-weight-bold d-block text-nowrap">
              {{ getRequestInfo(data.item).buyer.companyName }}
            </span>
            <small class="text-muted">
              {{ getRequestInfo(data.item).buyer.country }}
            </small>
          </b-media>
        </template>

        <!-- Column: Product -->
        <template #cell(products)="data">
          <div
            v-for="product in data.item.products"
            :key="product.id"
          >
            <span>- {{ product.name }}</span>
          </div>
        </template>

        <!-- Column: Supplier -->
        <template #cell(supplier)="data">
          <template v-if="getRequestInfo(data.item).supplier.exists">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="32"
                  :src="getRequestInfo(data.item).supplier.avatar_url"
                  :text="avatarText(getRequestInfo(data.item).supplier.name)"
                  variant="light-primary"
                />
              </template>
              <span class="font-weight-bold d-block text-nowrap">
                {{ getRequestInfo(data.item).supplier.companyName }}
              </span>
              <small class="text-muted">
                {{ getRequestInfo(data.item).supplier.country }}
              </small>
            </b-media>
          </template>
        </template>

        <!-- Column: Request Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="resolveRequestStatus(data.item.status).variant"
            class="px-1"
          >
            {{ resolveRequestStatus(data.item.status).status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-link :to="{ name: 'trading-requests-preview', params: { id: data.item.id } }">
              <feather-icon
                :id="`request-row-${data.item.id}-preview-icon`"
                icon="EyeIcon"
                size="16"
                class="mx-1"
              />
            </b-link>
            <b-tooltip
              title="Preview Request"
              :target="`request-row-${data.item.id}-preview-icon`"
            />
            <b-link
              v-if="data.item.can_edit && !data.item.deal"
              :to="{ name: 'trading-requests-add', params: { id: data.item.id } }"
            >
              <feather-icon icon="EditIcon" />
            </b-link>
          </div>
        </template>
      </b-table>
    </b-card>
    <template v-else>
      <open-request-list
        v-if="showRequests"
        :requests="items"
        :item-view="itemView"
      />
      <div
        v-else
        class="loader-container"
      >
        <b-spinner />
      </div>
    </template>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">
            {{ $t('tableDetails', { from: dataMeta.from, to: dataMeta.to, of: dataMeta.of }) }}
          </span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRequests"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BMedia, BAvatar, BButton, BBadge,
  BPagination, BTooltip, BLink, BFormRadioGroup, BFormRadio, BSpinner,
} from 'bootstrap-vue';
import { avatarText } from '@core/utils/filter';
import vSelect from 'vue-select';
import { onMounted, ref } from '@vue/composition-api';
import TradingRequestFilter from '@/views/trading/trading-requests/TradingRequestFilter.vue';
import OpenRequestList from '@/views/trading/trading-requests/OpenRequestList.vue';
import { useShopUi } from '@/views/trading/trading-offers/useOpenOffersHandler';
import TableLoader from '@/views/tables/TableLoader.vue';
import useRequestsList from './useRequestsList';

export default {
  components: {
    TableLoader,
    BSpinner,
    TradingRequestFilter,
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    BButton,
    OpenRequestList,
    BPagination,
    BTooltip,
    BLink,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    vSelect,
  },
  computed: {
    isSupplier() {
      return this.$store.getters['profile/isSupplierOnly'] || this.currentRequestsType === 'open';
    },
    currentRequestsType() {
      return this.$route.params.param;
    },
  },
  watch: {
    currentRequestsType() {
      this.refetchData();
    },
  },
  setup() {
    const {
      fetchRequests,
      tableColumns,
      items,
      perPage,
      currentPage,
      totalRequests,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refRequestListTable,
      resolveRequestStatus,

      buyerFilter,
      buyerCountriesFilter,
      statusFilter,
      verifiedFilter,

      getRequestInfo,
      sortCompare,
      refetchData,
      resolveDate,

      resolveRequestStatusVariantAndIcon,
    } = useRequestsList();

    const showRequests = ref(false);
    const isLoading = ref(false);

    onMounted(async () => {
      isLoading.value = true;

      await fetchRequests().finally(() => {
        showRequests.value = true;
        isLoading.value = false;
      });
    });
    const {
      itemView, itemViewOptions,
    } = useShopUi();

    return {
      itemView,
      itemViewOptions,
      fetchRequests,
      tableColumns,
      items,
      perPage,
      currentPage,
      totalRequests,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refRequestListTable,
      resolveRequestStatus,

      buyerFilter,
      buyerCountriesFilter,
      statusFilter,
      verifiedFilter,
      resolveDate,

      refetchData,
      sortCompare,

      getRequestInfo,

      avatarText,
      resolveRequestStatusVariantAndIcon,
      showRequests,
      isLoading,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.request-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';
</style>

<style scoped lang="scss">
@import 'src/@core/scss/vue/libs/vue-select.scss';

.loader-container {
  color: #7367f0;
  margin-top: 20vh;
  margin-left: 38vw;
}
</style>
