<template>
  <div>
    <section :class="itemView">
      <b-card
        v-for="item in requests"
        :key="item.id"
        class="ecommerce-card"
        no-body
      >
        <b-card-body>
          <div class="product-info">
            <div>
              <div class="item-name">
                {{ item.title }}
              </div>
            </div>
            <div>
              <div
                v-if="item.products.length - 1 > 0"
                class="grid-products"
              >
                <div style="display: flex; flex-direction: row; flex-flow: wrap;">
                  <div class="products-caption">
                    {{ $t('productsOfRequest') }}:
                  </div>
                  <div
                    v-for="product in item.products"
                    :key="product.id"
                    :class="{'column-direction' : itemView === 'grid-view'}"
                  >
                    <b-badge
                      variant="light-primary"
                      class="product-badge"
                    >
                      {{ product.name }}
                    </b-badge>
                  </div>
                </div>
              </div>
              <div
                v-else
                class="grid-products"
              >
                <span class="product-caption">
                  {{ $t('productOfRequest') }}:
                </span>
                <b-badge
                  variant="light-primary"
                  class="product-badge"
                >
                  {{ item.products[0].name }}
                </b-badge>
              </div>
            </div>
          </div>
          <div class="d-flex bottom-info">
            <div class="d-flex align-items-center lead-time grid-request-term">
              <h6 class="m-0 mr-1">
                {{ $t('requestTerm') }}:
              </h6>
              <b-badge
                v-if="item.delivery_datetime"
                :variant="deadlineBadgeVariants[item.id] || 'light-primary'"
              >
                {{ deadlineDayMessages[item.id] }}
              </b-badge>
              <b-badge
                v-else
                variant="light-success"
              >
                {{ $t('unlimited') }}
              </b-badge>
            </div>
          </div>
        </b-card-body>

        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-button
            variant="light"
            class="btn-wishlist mt-0"
            :to="{ name: 'trading-requests-preview', params: { id: item.id } }"
          >
            <span>{{ $t('moreInfo') }}</span>
          </b-button>

          <b-button
            variant="primary"
            class="btn-cart"
            :disabled="isOfferCreating"
            @click="createOffer(item.id)"
          >
            <feather-icon
              icon="ShoppingCartIcon"
              class="mr-50"
            />
            <span>{{ $t('createAnOffer') }}</span>
          </b-button>
        </div>
      </b-card>
    </section>
  </div>
</template>

<script>
import { resolveProductsData } from '@/utils/offerUtils.js';
import {
  BBadge, BButton,
  BCard, BCardBody,
} from 'bootstrap-vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import i18n from '@/libs/i18n';

export default {
  name: 'OpenRequestList',
  components: {
    BCard,
    BBadge,
    BCardBody,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    BButton,
  },
  props: {
    itemView: {
      type: String,
      default: 'list-view',
    },
    requests: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isOfferCreating: false,
      deadlineBadgeVariants: {},
      deadlineDayMessages: {},
    };
  },
  created() {
    this.requests.forEach(request => {
      this.getDeadlineData(request.id, request.delivery_datetime);
    });
  },
  methods: {
    resolveProductsData,
    getDeadlineData(id, date) {
      const daysLeft = Math.floor((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));

      if (daysLeft > 0) {
        this.deadlineDayMessages[id] = daysLeft === 1 ? i18n.t('oneDayMore') : i18n.t('daysMore', { qty: daysLeft });
      } else if (!daysLeft) {
        this.deadlineBadgeVariants[id] = 'light-warning';
        this.deadlineDayMessages[id] = i18n.t('today');
      } else {
        this.deadlineBadgeVariants[id] = 'light-danger';
        this.deadlineDayMessages[id] = i18n.t('expired');
      }
    },
    async createOffer(id) {
      this.isOfferCreating = true;

      try {
        const { data: { data } } = await this.$http.post(`/requests/by-offer/${id}`);

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('offerFromRequestMessage', { id }),
            icon: 'EditIcon',
            variant: 'success',
          },
        });

        await this.$router.push({ name: 'trading-offers-add', params: { id: data.offer.id } });
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('somethingWentWrong', { msg: e.response.data.message }),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      } finally {
        this.isOfferCreating = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import 'src/@core/scss/base/core/colors/palette-variables.scss';
@import "src/@core/scss/base/components/_variables-dark.scss";

.list-view {
  .bottom-info {
    border-top: 1px solid $border-color;
    padding-top: 1rem;
  }
  .product-info {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  .ecommerce-card {
    //background-color: #2c4762 !important;
    width: 100% !important;
    display: flex !important;
    flex-direction: row;

    .card-body {
      width: 80% !important;
      //width: 68rem !important;
    }

    .item-name {
      font-size: 18px;
      font-weight: 600;
    }

    .item-options {
      width: 20% !important;
      //width: 16rem !important;
    }
  }
}

.grid-view {
  background-color: #62ffb5;

  .item-name {
    font-size: 18px;
    font-weight: 600;
  }

  .column-direction {
    //display: flex;
    flex-direction: row;
    width: fit-content;
    //background-color: red !important;
  }

  .grid-request-term {
    justify-content: left;
  }

  .grid-products {
    margin: 10px 0;
  }

  .product-caption, .products-caption {
    font-weight: 500;
  }
}

.products-caption {
  margin-top: 4px;
  margin-right: 3px;
}

.product-group {
  padding: 5px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: $light;
}
.dark-layout {
  .list-view {
    .bottom-info {
      border-top: 1px solid $table-dark-border-color;
      padding-top: 1rem;
    }
  }
  .product-group {
    background: $theme-dark-body-bg;
  }
}
</style>

<style lang="scss" scoped>
.item-view-radio-group ::v-deep {
  .btn {
    display: flex;
    align-items: center;
  }
}
.card-img-top {
  max-height: 130px;
}

.product-badge {
  margin: 4px 3px;
}
</style>
